export const NUMERO = "76666494";
export const WHATSAPP = "591" + NUMERO;
export const CORREO = "kaidev.srl@gmail.com";
export const MESSAGE = "Hola+T%c3%bcvi%2c+ay%c3%badame+con+mi+viaje.";
//"Hola%20T%C3%BCvi%2C%20%C2%A1help%20me%20out%21%0ADeseo%20viajar%20";
export const LINK_WHATSAPP = "https://api.whatsapp.com/send";
export const LINK_COMPLETE_WHATSAPP = `${LINK_WHATSAPP}?phone=${WHATSAPP}&text=${MESSAGE}`;
export const LINK_FACEBOOK = "https://www.facebook.com/Tuvi-106110914664443/";
export const LINK_PUNTO_PAGO =
  "http://demo.pagosnet.com.bo/#/tempo/geolocalizacion";
