export const ESTADO_VIAJE_ENCOMIENDA = {
  ORIGEN: 1,
  DESTINO: 2,
  ENCURSO: 3,
};

export function labelEstadoViaje(estado) {
  switch (estado) {
    case ESTADO_VIAJE_ENCOMIENDA.ORIGEN:
      return 'Origen';
    case ESTADO_VIAJE_ENCOMIENDA.DESTINO:
      return 'Destino';
    case ESTADO_VIAJE_ENCOMIENDA.ENCURSO:
      return 'En curso';
  }
}

export enum TipoServicio {
  SucursalSucursal = 1,
  SucursalDomicilio = 2,
  DomicilioSucursal = 3,
  DomicilioDomicilio = 4,
}

export const TIPO_SERVICIO_OPTIONS = [
  {
    value: TipoServicio.SucursalSucursal,
    label: 'Sucursal - Sucursal',
  },
  {
    value: TipoServicio.SucursalDomicilio,
    label: 'Sucursal - Domicilio ',
  },
  {
    value: TipoServicio.DomicilioSucursal,
    label: 'Domicilio - Sucursal',
  },
  {
    value: TipoServicio.DomicilioDomicilio,
    label: 'Domicilio - Domicilio',
  },
];

export const getTipoServicio = value => {
  switch (value) {
    case TipoServicio.SucursalDomicilio:
      return 'Sucursal - Domicilio';
    case TipoServicio.SucursalSucursal:
      return 'Sucursal - Sucursal';
    case TipoServicio.DomicilioDomicilio:
      return 'Domicilio - Domicilio';
    case TipoServicio.DomicilioSucursal:
      return 'Domicilio - Sucursal';
    default:
      return 'Tipo de solicitud no válida';
  }
};

export enum TipoSolicitud {
  Pagada = 1,
  PorPagar = 2,
}

export const TIPO_SOLICITUD_OPTIONS = [
  {
    value: TipoSolicitud.Pagada,
    label: 'Pagada',
  },
  {
    value: TipoSolicitud.PorPagar,
    label: 'Por pagar',
  },
];

export const getTipoSolicitud = value => {
  switch (value) {
    case TipoSolicitud.Pagada:
      return 'Pagada';
    case TipoSolicitud.PorPagar:
      return 'Por pagar';
    default:
      return 'Tipo de solicitud no válida';
  }
};

export enum TipoPago {
  Efectivo = 1,
  TarjetaFisica = 2,
  TarjetaOnline = 3,
}

export const TIPO_PAGO_OPTIONS = [
  {
    value: TipoPago.Efectivo,
    label: 'Efectivo',
  },
  {
    value: TipoPago.TarjetaFisica,
    label: 'Tarjeta fisica',
  },
  {
    value: TipoPago.TarjetaOnline,
    label: 'Tarjeta online',
  },
];

export const getTipoPago = value => {
  switch (value) {
    case TipoPago.Efectivo:
      return 'Efectivo';
    case TipoPago.TarjetaFisica:
      return 'Tarjeta fisica';
    case TipoPago.TarjetaOnline:
      return 'Tarjeta online';
    default:
      return 'Tipo de pago no valido';
  }
};

export enum Prioridad {
  Alta = 1,
  Normal = 2,
  Baja = 3,
}

export const PRIORIDAD_OPTIONS = [
  {
    value: Prioridad.Alta,
    label: 'Alta',
  },
  {
    value: Prioridad.Normal,
    label: 'Normal',
  },
  {
    value: Prioridad.Baja,
    label: 'Baja',
  },
];

export const getPrioridad = value => {
  switch (value) {
    case Prioridad.Alta:
      return 'Alta';
    case Prioridad.Normal:
      return 'Normal';
    case Prioridad.Baja:
      return 'Baja';
    default:
      return 'prioridad no valido';
  }
};

export enum TipoCuidado {
  EnCabina = 1,
  Fragil = 2,
}

export const TIPO_CUIDADO_OPTIONS = [
  {
    value: TipoCuidado.EnCabina,
    label: 'En cabina',
  },
  {
    value: TipoCuidado.Fragil,
    label: 'Fragil',
  },
];

export const getTipoCuidado = value => {
  switch (value) {
    case TipoCuidado.EnCabina:
      return 'En cabina';
    case TipoCuidado.Fragil:
      return 'Fragil';
    default:
      return 'Cuidado no valido';
  }
};

export enum EstadoMovimiento {
  Registrado = 1,
  Recepcionado = 2,
  Despacho = 3,
  Desembarque = 4,
  EnDestino = 5,
  Entregado = 6,
  Anulado = 7,
}

export const getEstadoMovimiento = value => {
  switch (value) {
    case EstadoMovimiento.Registrado:
      return 'Registrado';
    case EstadoMovimiento.Recepcionado:
      return 'Recepcionado';
    case EstadoMovimiento.Despacho:
      return 'Despacho';
    case EstadoMovimiento.Desembarque:
      return 'Desembarque';
    case EstadoMovimiento.EnDestino:
      return 'En destino';
    case EstadoMovimiento.Entregado:
      return 'Entregado';
    case EstadoMovimiento.Anulado:
      return 'Anulado';
    default:
      return 'Estado no valido';
  }
};
