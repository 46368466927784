import { createMuiTheme } from "@material-ui/core";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

interface PaletteColor {
  light?: string;
  main: string;
  dark?: string;
  contrastText?: string;
}

// Green - Blue
export const primary: PaletteColor = {
  main: "#132968",
  dark: "#0d1c48",
  light: "#0d1c48",
  contrastText: "#FFF",
};

export const secondary: PaletteColor = {
  main: "#FA6B6B",
  dark: "#af4a4a",
  light: "#fb8888",
  contrastText: "#FFF",
};

export const success: PaletteColor = {
  main: "#2e7d32",
};

export const error: PaletteColor = {
  main: "#c62828",
};

export const warning: PaletteColor = {
  main: "#f9a825",
};

const typography: TypographyOptions = {
  fontFamily: ["GT-Walsheim", "Roboto"].join(","),
  h6: {
    fontWeight: "bold",
  },
};

// const anotherOne: PaletteColorOptions = {
//   main: "#64b5f6",
//   light: "#9be7ff",
//   dark: "#2286c3",
//   contrastText: "#FFF",
// };

export const LightTheme = createMuiTheme({
  palette: {
    primary: primary,
    secondary: secondary,
    success: success,
    error: error,
  },
  typography,
});
