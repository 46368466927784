import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { isAllowGA } from "utils/ga";

const GAListener = ({ children }) => {
  useGATracking();
  return children;
};

export default GAListener;

const useGATracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  const initReactPixel = () => {
    ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
    ReactPixel.pageView();
  };

  useEffect(() => {
    if (isAllowGA()) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
      initReactPixel();
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.hostname === "localhost") console.log("Es local");
    if (initialized && isAllowGA()) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }, [location, initialized]);
};

export class GAEvents {
  static busquedaBuscarDestino() {
    console.log("se llamo BBD");
    if (isAllowGA()) {
      ReactGA.event({
        category: "Busqueda",
        action: "Buscar destino",
        label: "Elegir destino",
      });
      ReactPixel.trackCustom("Buscar destino", {
        label: "Elegir destino",
      });
    }
  }

  static busquedaModyBuscarDestino() {
    console.log("se llamo BMBD");
    if (isAllowGA()) {
      ReactGA.event({
        category: "Busqueda",
        action: "Modificar busqueda",
        label: "Elegir destino",
      });
      ReactPixel.trackCustom("Modificar busqueda", {
        label: "Elegir destino",
      });
    }
  }

  static compraAsientosResult() {
    console.log("se llamo CAR");
    if (isAllowGA()) {
      ReactGA.event({
        category: "Compra",
        action: "Ver asientos",
        label: "Elije el asiento del viaje y su disponibilidad",
      });
      ReactPixel.trackCustom("Ver asientos", {
        label: "Elije el asiento del viaje y su disponibilidad",
      });
    }
  }

  static compraContinuarDato() {
    console.log("se llamo CCD");
    if (isAllowGA()) {
      ReactGA.event({
        category: "Compra",
        action: "Continuar",
        label: "Ingreso de los datos del pasajero",
      });
      ReactPixel.trackCustom("Continuar", {
        label: "Ingreso de los datos del pasajero",
      });
    }
  }

  static compraReservarDone() {
    console.log("se llamo CRD");
    if (isAllowGA()) {
      ReactGA.event({
        category: "Compra",
        action: "Reservar",
        label: "Realizar reserva",
      });
      ReactPixel.trackCustom("Reservar", {
        label: "Realizar reserva",
      });
    }
  }

  static busquedaBuscarEncomienda() {
    console.log("se llamo BBE");
    if (isAllowGA()) {
      ReactGA.event({
        category: "Busqueda",
        action: "Buscar encomienda",
        label: "Rastreo de carga",
      });
      ReactPixel.trackCustom("Buscar encomienda", {
        label: "Rastreo de carga",
      });
    }
  }

  static rsFacebook() {
    console.log("se llamo RSF");
    if (isAllowGA()) {
      ReactGA.event({
        category: "Redes sociales",
        action: "Ir facebook",
        label: "click boton de facebook",
      });
      ReactPixel.trackCustom("Ir facebook", {
        label: "click boton de facebook",
      });
    }
  }

  static rsWhatsApp() {
    console.log("se llamo RSWA");
    if (isAllowGA()) {
      ReactGA.event({
        category: "Redes sociales",
        action: "Ir whatsApp",
        label: "click boton de whatsApp",
      });
      ReactPixel.trackCustom("Ir whatsApp", {
        label: "click boton de whatsApp",
      });
    }
  }
}
