import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Facebook from "@material-ui/icons/Facebook";
import Search from "@material-ui/icons/Search";
import { useStyles } from "./style";
import { Login } from "../../pages";
// import { AuthTypeView } from "../../pages/login/view.enum";
// import Auth from "../../service/Auth";
// import { AuthService } from "../../service";
import {
  ROUTE_PAGE,
  LINK_FACEBOOK,
  LINK_COMPLETE_WHATSAPP,
} from "constants/index";
import { Link, useHistory } from "react-router-dom";
// import LogoL from "../../assets/logo/tuvil.svg";
import LogoWhite from "assets/images/tuvi-blanco.svg";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as What } from "./../../assets/images/what.svg";
import { GAEvents } from "components/GAListener";
//import { ReactComponent as Facebook } from "../../assets/images/facebook.svg";
//import { ReactComponent as WhatsApp } from "../../assets/images/whatsapp.svg";
//import { ReactComponent as Instagram } from "../../assets/images/instagram.svg";
export default function Header() {
  const classes = useStyles(); //AuthTypeView
  const history = useHistory();
  //const [, , LoginForm] = Login();
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [
  //   mobileMoreAnchorEl,
  //   setMobileMoreAnchorEl,
  // ] = React.useState<null | HTMLElement>(null);

  // const isMenuOpen = Boolean(anchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const handleMobileMenuClose = () => {
  //   setMobileMoreAnchorEl(null);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };

  // const logout = async () => {
  //   await AuthService.logOut();
  //   handleMobileMenuClose();
  //   handleMenuClose();
  // };
  // const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  // const handleMobileMenuAuth = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const menuId = "primary-search-account-menu";
  // const renderMenu = (
  //   <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
  //   </Menu>
  // );

  // const mobileMenuId = "primary-search-account-menu-mobile";
  // const renderMobileMenu = (
  //   <StyledMenu
  //     id={mobileMenuId}
  //     anchorEl={mobileMoreAnchorEl}
  //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //     keepMounted
  //     open={isMobileMenuOpen}
  //     onClose={handleMobileMenuClose}
  //   >
  //     <StyledMenuItem
  //       onClick={(props) => {
  //         setStepAuth(AuthTypeView.LOGIN);
  //         setOpenDialog(true);
  //         handleMobileMenuClose();
  //       }}
  //     >
  //       <ListItemIcon>
  //         <LockOpen fontSize="small" />
  //       </ListItemIcon>
  //       <ListItemText primary="Iniciar sesión" />
  //     </StyledMenuItem>
  //     <StyledMenuItem
  //       onClick={() => {
  //         setStepAuth(AuthTypeView.SIGNUP);
  //         setOpenDialog(true);
  //         handleMobileMenuClose();
  //       }}
  //     >
  //       <ListItemIcon>
  //         <Person fontSize="small" />
  //       </ListItemIcon>
  //       <ListItemText primary="Crear cuenta" />
  //     </StyledMenuItem>
  //   </StyledMenu>
  // );
  // const AuthUser = () => {
  //   return (
  //     <div className={classes.avatarInfo}>
  //       <Avatar>
  //         <Person fontSize="inherit" />
  //       </Avatar>
  //       <Typography className={classes.avatarName}>Luis Yerko</Typography>
  //     </div>
  //   );
  // };
  // const menuDesktop = (
  //   <Grid>
  //     <div className={classes.sectionDesktop}>
  //       {Auth.isAuthenticated() ? (
  //         <>
  //           {AuthUser()}
  //           <IconButton aria-label="log out" onClick={logout} color="inherit">
  //             <ExitToAppIcon />
  //           </IconButton>
  //         </>
  //       ) : (
  //         <>
  //           <Button
  //             color="inherit"
  //             onClick={() => {
  //               setStepAuth(AuthTypeView.LOGIN);
  //               setOpenDialog(true);
  //             }}
  //           >
  //             Iniciar sesión
  //           </Button>
  //           <Button
  //             color="inherit"
  //             onClick={() => {
  //               setStepAuth(AuthTypeView.SIGNUP);
  //               setOpenDialog(true);
  //             }}
  //           >
  //             Crear cuenta
  //           </Button>
  //         </>
  //       )}
  //     </div>

  //     <div className={classes.sectionMobile}>
  //       {Auth.isAuthenticated() ? AuthUser() : null}
  //       <IconButton
  //         aria-label="show more"
  //         aria-controls={mobileMenuId}
  //         aria-haspopup="true"
  //         onClick={
  //           Auth.isAuthenticated() ? handleMobileMenuAuth : handleMobileMenuOpen
  //         }
  //         color="inherit"
  //       >
  //         <MoreIcon />
  //       </IconButton>
  //     </div>
  //   </Grid>
  // );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        {process.env.REACT_APP_DEVELOPMENT_MODE && (
          <Alert severity="warning">Estás en modo desarrollo</Alert>
        )}
        <Toolbar>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              container
              justify="space-between"
              alignItems="center"
              item
              md={8}
              xs={12}
            >
              <Grid>
                <Link
                  to={ROUTE_PAGE.INICIO}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  {/* <img width="100px" height="100%" src={LogoL}></img> */}
                  <img width="100px" height="100%" src={LogoWhite} alt="logo" />
                </Link>
              </Grid>
              <Grid>
                <IconButton
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Link Facebook"
                  href={LINK_FACEBOOK}
                  onClick={() => GAEvents.rsFacebook()}
                  style={{ color: "#FFF" }}
                >
                  <Facebook
                    style={{
                      fontSize: "2.6rem",
                    }}
                    fontSize="large"
                  />
                </IconButton>

                <IconButton
                  target="_blank"
                  rel="noreferrer"
                  href={LINK_COMPLETE_WHATSAPP}
                  onClick={() => GAEvents.rsWhatsApp()}
                  style={{ color: "#25D366" }}
                >
                  <What
                    style={{
                      fontSize: "2.1875rem",
                      width: "1em",
                      height: "1em",
                    }}
                  />
                </IconButton>
                <Button
                  onClick={() =>
                    history.push(ROUTE_PAGE.ENCOMIENDA.SEGUIMIENTO)
                  }
                  style={{ color: "white" }}
                  startIcon={<Search></Search>}
                >
                  Encomienda
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* <LoginForm></LoginForm> */}
    </div>
  );
}
