import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

const footerData = [
  {
    title: "Nosotros",
    list: [
      { title: "¿Quienes somos?", link: "/contacto" },
      { title: "Misión y visión", link: "/contacto" },
    ],
  },
  {
    title: "Servicio al cliente",
    list: [
      { title: "Contacto", link: "/contacto" },
      { title: "Condiciones de uso", link: "/contacto" },
      { title: "Políticas de privacidad", link: "/contacto" },
      { title: "Política de cookies", link: "/contacto" },
    ],
  },
  {
    title: "Rutas",
    list: [
      { title: "Ruta Santa Cruz - La Paz", link: "/contacto" },
      { title: "Ruta Tarija - La Paz", link: "/contacto" },
      { title: "Ruta Santa Cruz - Puerto Quijarro", link: "/contacto" },
      { title: "Ruta Puerto Quijarro - Santa Cruz", link: "/contacto" },
      { title: "Ruta Cochabamba-La Paz", link: "/contacto" },
    ],
  },
  {
    title: "Terminales",
    list: [
      { title: "Terminal de La Paz", link: "/contacto" },
      { title: "Terminal de Santa Cruz", link: "/contacto" },
      { title: "Terminal de Montero", link: "/contacto" },
      { title: "Terminal de Oruro", link: "/contacto" },
      { title: "Terminal de Trinidad", link: "/contacto" },
      { title: "Terminal de Camiri", link: "/contacto" },
    ],
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      static: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    title: {
      color: theme.palette.secondary.main,
    },
    item: {
      color: "inherit",
      textDecoration: "inherit" /* no underline */,
    },
  })
);

export default function Footer() {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.footer}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item md={8} xs={12}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            {footerData.map((data, index) => (
              <Grid item md={3} xs={12} key={index}>
                <Typography variant="h6" className={classes.title}>
                  {data.title}
                </Typography>
                <List dense={true}>
                  {data.list.map((items, i) => (
                    <ListItem key={i}>
                      <Link to={items.link} className={classes.item}>
                        <ListItemText primary={items.title} />
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">
              Copyright &copy; 2020. Desarrollado por Kaidev. Todos los derechos
              reservados
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
