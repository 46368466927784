export const ROUTE_PAGE = {
  INICIO: "/",
  //LOGIN
  LOGIN: "/login",
  //AJUSTE
  EMPRESA: {
    LISTAR: "/empresa",
    NUEVO: "/empresa/nuevo",
  },
  ROLPRIVI: {
    LISTAR: "/rol",
    NUEVO: "/rol/nuevo",
    EDITAR: "/rol/edicion/:id",
  },
  FUNCIONALIDAD: {
    LISTAR: "/funcionalidad",
    NUEVO: "/funcionalidad/nuevo",
    EDITAR: "/funcionalidad/nodo/:id",
  },
  //ADMINISTRACION
  TIPOPERSONAL: {
    NUEVO: "/tipopersonal/nuevo",
    EDITAR: "/tipopersonal/editar/:id",
  },
  PRS_EMPRESA: {
    NUEVO: "/personal/empresa/nuevo",
    EDITAR: "/personal/empresa/editar/:id",
    LISTAR: "/personal/empresa",
  },
  SUCURSAL: {
    NUEVO: "/sucursal/nuevo",
    LISTAR: "/sucursal",
    EDITAR: "/sucursal/editar/:id",
  },
  BUSNIT: {
    NUEVO: "/bus/nit/nuevo",
    LISTAR: "/bus/nit",
    EDITAR: "/bus/nit/editar/:id",
  },
  ///PLANIFICACION
  PLANIFICACION: {
    LISTAR: "/planificacion",
    NUEVO: "/planificacion/nuevo",
    EDITAR: "/planificacion/editar/:id",
  },
  ///VIAJE
  VIAJE: {
    LISTAR: "/viaje",
    PERSONAL: "/viaje/personal/:id",
    ABORDO: "/viaje/abordo/:id",
    PASAJEROS: "/viaje/pasajeros/:id",
    GRAFICO: "/venta/viaje/:id",
    RESERVAS: "/viaje/reserva/",
  },
  VENTA: {
    THANKS: "/finalizacion/:id",
  },
  ENCOMIENDA: {
    SEGUIMIENTO: "/seguimiento",
  },
  RETRY: {
    TIGO_MONEY: '/retry/payment/tigo-money/:codigo'
  }
};
export const PARAM = (route, param) => {
  //Si el object esta vacio
  let isEmpty = (data) => {
    return Object.keys(data).length === 0;
  };
  //Si es un object
  let isObject = (data) => {
    return typeof data === "object" && data !== null && data !== undefined;
  };
  let copyUrl = route;
  if (isObject(param) && !isEmpty(param)) {
    Object.keys(param).forEach((key) => {
      copyUrl = copyUrl.replace(":" + key, param[key]);
    });
  }
  return copyUrl;
};
