export const URL = process.env.REACT_APP_URL;
export const URL_QR = process.env.REACT_APP_URL_QR;
export const USUARIO = "/api/usuario";
export const VENTA = "/api/venta";
export const TRANSPORTE = "/api/transporte";
export const ENCOMIENDA = "/api/encomienda";

export const API = {
  LOGIN: {
    NORMAL: URL + "/auth/public",
    SOCIAL: URL + "/auth/social/login",
  },
  AUTH: {
    REFRESH: URL + "/refresh",
  },
  SIGNUP: URL + "/auth/signup",
  LOGOUT: URL + USUARIO + "/auth/logout",
  UBICACION: {
    GET_UBICATION: URL + "/vp/ubicacion",
    GET_FOTO: URL + "/vp/ubicacion/files/:name",
  },
  RESERVA: {
    GET: URL + "/vp/reserva/",
    ROLLBACK: URL + "/vp/reserva/rollback",
    GET_QR: `${URL_QR}/:name`,
    GET_LINK: `${URL}/vp/reserva/link/`,
  },
  EMPRESA: {
    LOGO: URL + "/empresa/upload",
    GET_ALL: URL + "/up/empresa",
    LOGO_BY_EMPRESA: URL + "/up/empresa/logo/:id",
  },
  VIAJE: {
    GET_TRAVEL_SEARCH: URL + "/vp/viaje/search",
    GET_PRICE_BY_ZONE: URL + "/vp/zona-detalle/precio",
    GET_OCCUPIED: URL + "/vp/boleto/ocupado",
    GET_DETAIL: URL + "/vp/viaje/detalle",
    GET_ROUTE: URL + "/vp/ruta",
    GET_DETAIL_ALL: URL + "/vp/viaje/detalle/all",
    GET_VALIDATION: URL + "/vp/viaje/validate",
  },
  RUTA: {
    GET_FOTO: `${URL}/vp/viaje/files/:name`,
  },
  TRANSPORTE: {
    GET_BUS: URL + "/tp/bus/:id",
    GET_BUSES_INFO: URL + "/tp/bus/buses",
    GET_CARACTERISTICAS: URL + "/tp/tipocaracteristicas",
  },
  PAGO: {
    CREATE_RECAUDACION: URL + "/vp/payment/recaudacion",
    GET_STATE_TIGO: `${URL}/vp/payment/tigo-money/checkstatus`,
    CONFIRM_PAGO_TIGO: `${URL}/vp/payment/tigo-money/notification`,
    PAYMENT_REQUEST_TIGO: `${URL}/vp/payment/tigo-money/solicitudpago`,
    GET_INFO_PAYMENT: `${URL}/vp/payment/tigo-money/modaldata`,
  },
  //encomiendas
  SUCURSAL: {
    GET_MANY: URL + "/vp/sucursal/many",
  },
  MOVIMIENTO: {
    GET_MOVIMIENTO_PUBLIC: URL + "/encomienda/public/guia/consulta",
    GET_MOVIMIENTO_DETALLADO_ADMIN: URL + "/ep/movimiento/detalle",
  },
  FACTURA: {
    VERIFY_ENCOMIENDA: URL + "/vp/factura/encomienda/verify/:id",
    GET_ENCOMIENDA: URL + "/vp/factura/encomienda/impresion/:id",
    GET_FACTURA: URL + "/vp/factura/encomienda/generate/",
  },
  SOLICITUD_ENCOMIENDA: {
    GET: URL + "/ep/solicitud-encomienda/",
    GET_BY_ID: URL + "/ep/solicitud-encomienda/:id",
    VERIFY_PASSWORD: URL + "/ep/solicitud-encomienda/verify/",
  },
  GUIA_ENCOMIENDA: {
    SET_FACTURA: URL + "/ep/guia-encomienda/factura/:id",
    GET_FACTURA: URL + "/ep/guia-encomienda/factura/info/:id",
  },
};
