import React, { Component } from "react";
import { Snackbar, IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";

export class NotificationQueue extends Component {
  state = {
    queue: [],
    activeNotification: undefined,
  };

  closeIcon = () => (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={() => {
        this.closeNotification();
      }}
    >
      <CloseIcon />
    </IconButton>
  );

  action = (text, callback) => {
    return (
      <Button
        color="secondary"
        size="small"
        onClick={() => {
          callback();
          this.closeNotification();
        }}
      >
        {text}
      </Button>
    );
  };

  closeNotification = (e, reason) => {
    // Para que no desaparezca al hacer clic en otro lado
    if (reason === "clickaway") {
      return;
    }
    const { queue, activeNotification } = this.state;

    // Cerrar la notificación activa
    activeNotification.open = false;
    this.setState({ activeNotification: activeNotification });

    // Ejecuta el callback, si es que existe
    if (activeNotification.closeCallback !== undefined)
      activeNotification.closeCallback();

    // Espera 1 segundo antes de mostrar la siguiente notificación
    setTimeout(() => {
      // Elimina de la cola
      queue.shift();

      // Popea la nueva notificación
      const notification = queue[0];
      this.setState({
        activeNotification: notification,
      });
    }, 1000);
  };

  createNotification = ({
    type,
    message,
    closeCallback,
    action,
    actionCallback,
    duration = 3,
  }) => {
    const { queue, activeNotification } = this.state;

    // Define cual acción utilizar
    const actionElement =
      action && actionCallback
        ? this.action(action, actionCallback)
        : this.closeIcon();

    // Crea el objecto de Notificación
    const notification = {
      open: true,
      type,
      message,
      duration,
      closeCallback,
      actionCallback,
      action: actionElement,
    };

    // Lo inserta en la cola
    queue.push(notification);

    // Verifica si es necesario mostrarlo o no
    if (activeNotification === undefined) {
      this.setState({
        activeNotification: notification,
        queue: queue,
      });
    } else {
      this.setState({
        queue: queue,
      });
    }
  };

  render() {
    const { activeNotification } = this.state;
    return (
      activeNotification !== undefined && (
        <Snackbar
          open={activeNotification.open}
          autoHideDuration={activeNotification.duration * 1000}
          onClose={this.closeNotification}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          message={activeNotification.message}
          action={activeNotification.action}
        >
          {activeNotification.type && (
            <Alert
              variant="filled"
              onClose={this.closeNotification}
              severity={activeNotification.type}
            >
              {activeNotification.message}
            </Alert>
          )}
        </Snackbar>
      )
    );
  }
}

export class NotificationSystem extends Component {
  static reference = null;

  static getReference = () => {
    if (NotificationSystem.reference === null) {
      NotificationSystem.reference = React.createRef();
    }
    return NotificationSystem.reference;
  };

  /**
   * Add a success notification to the notification queue to be displayed after the current notification is closed.
   *
   * @param {Object} notification
   * @param {String} notification.message The message that will be displayed inside the notification
   * @param {number} [notification.duration=3] The duration of the notification (in seconds). This value must be between 3 and 10 seconds. This value is optional.
   * @param {String} [notification.action=null] The text displayed in the action button (if any). This value is optional.
   * @param {function} [notification.actionCallback=null] The function executed when the action button is clicked. This value is optional.
   * @param {function} [notification.closeCallback=null] The function executed when the notification is closed. This value is optional.
   */
  static actionNotification(notification) {
    let instance = NotificationSystem.getReference();
    if (instance.current) {
      instance.current.createNotification({ ...notification });
    }
  }

  /**
   * Add a success notification to the notification queue to be displayed after the current notification is closed.
   *
   * @param {Object} notification
   * @param {String} notification.message The message that will be displayed inside the notification
   * @param {number} [notification.duration=3] The duration of the notification (in seconds). This value must be between 3 and 10 seconds. This value is optional.
   * @param {function} [notification.closeCallback=null] The function executed when the notification is closed. This value is optional.
   */
  static successNotification(notification) {
    let instance = NotificationSystem.getReference();
    if (instance.current) {
      instance.current.createNotification({ ...notification, type: "success" });
    }
  }

  /**
   * Add a danger notification to the notification queue to be displayed after the current notification is closed.
   *
   * @param {Object} notification
   * @param {String} notification.message The message that will be displayed inside the notification
   * @param {number} [notification.duration=3] The duration of the notification (in seconds). This value must be between 3 and 10 seconds. This value is optional.
   * @param {function} [notification.closeCallback=null] The function executed when the notification is closed. This value is optional.
   */
  static dangerNotification(notification) {
    let instance = NotificationSystem.getReference();
    if (instance.current) {
      instance.current.createNotification({ ...notification, type: "error" });
    }
  }

  /**
   * Add a warning notification to the notification queue to be displayed after the current notification is closed.
   *
   * @param {Object} notification
   * @param {String} notification.message The message that will be displayed inside the notification
   * @param {number} [notification.duration=3] The duration of the notification (in seconds). This value must be between 3 and 10 seconds. This value is optional.
   * @param {function} [notification.closeCallback=null] The function executed when the notification is closed. This value is optional.
   */
  static warningNotification(notification) {
    let instance = NotificationSystem.getReference();
    if (instance.current) {
      instance.current.createNotification({ ...notification, type: "warning" });
    }
  }

  render() {
    return <NotificationQueue ref={NotificationSystem.getReference()} />;
  }
}
