import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import LoadingPage from "./LoadingPage";

const LayoutRouteLazy = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Suspense fallback={<LoadingPage />}>
          <Component {...props} />
        </Suspense>
      </Layout>
    )}
  />
);

export default LayoutRouteLazy;
