import React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import es from "date-fns/locale/es";
// Styles
import "./styles/Base.scss";
import { NotificationSystem } from "./config/NotificationSystem";
import { ThemeProvider } from "@material-ui/core/styles";
import { LightTheme } from "./styles/Theme";
import GAListener from "components/GAListener";
import Rutas from "routes";

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={LightTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <NotificationSystem />
          <BrowserRouter>
            <GAListener>
              <Switch>
                <Rutas />
                <Redirect to="/404" />
              </Switch>
            </GAListener>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

export default App;
