export const FORMATO_TIEMPO = {
  FT1: "DD/MM/YYYY HH:mm A",
  FT2: "DD MMMM YYYY, HH:mm A",
  FT3: "DD MMM HH:mm A",
  FT4: "DD/MM/YYYY HH:mm:ss",
  FT5: "yyyy-MM-dd hh:mm a",
  FTTime: "hh:mm a",
  FTDate: "yyyy-MM-dd",
  FT6: "dd/MM/YYY",
  FT7: "dd/MM/YYY hh:mm:ss a",
};
