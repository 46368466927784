export const V_REQUIRED = '^Campo requerido';
export const V_NUMERO = '^El campo debe ser de tipo numerico';
export const V_NIT = '^El campo debe contener un nit válido';
export const V_CORREO = '^El campo debe ser un correo válido';
export const V_TOOLONG =
  '^El campo es demasiado largo (máximo %{count} caracteres)';
export const V_TOO_SHORT =
  '^El campo es demasiado corto (mínimo %{count} caracteres)';
export const V_CARNET = '^El campo debe contener una identificacion válida';
export const V_CI = '^El campo debe ser un ci válido';
export const V_EMAIL = '^El campo debe ser un correo válido';
export const V_NOT_EQUAL = '^No deben ser iguales';
