import React, { lazy } from "react";
// import { Login } from "./pages";
import { EmptyLayout, LayoutRouteLazy } from "./components/Layout";
import HomeLayout from "./components/Layout/HomeLayout";
import { ROUTE_PAGE } from "constants/index";

const rutas = [
  // {
  //   path: "/login",
  //   layout: HomeLayout,
  //   component: Login,
  // }
  {
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import('pages/principal/MainPage.tsx')),
  },
  {
    path: "/contacto",
    layout: HomeLayout,
    component: lazy(() => import('./pages/contacto/ContactoPage.tsx')),
  },
  {
    path: "/seguimiento",
    layout: HomeLayout,
    component: lazy(() => import('./pages/seguimiento/buscarEncomienda.tsx')),
  },
  {
    path: "/viajes",
    layout: HomeLayout,
    component: lazy(() => import('./pages/venta/VentaPage.tsx')),
  },
  {
    path: ROUTE_PAGE.VENTA.THANKS,
    layout: HomeLayout,
    component: lazy(() => import('./pages/post-purchase/PostPurchasePage.tsx')),
  },
  {
    path: ROUTE_PAGE.RETRY.TIGO_MONEY,
    layout: HomeLayout,
    component: lazy(() => import('./pages/retrytigo/RetryTigo.tsx')),
  },
  {
    path: "/404",
    layout: EmptyLayout,
    component: lazy(() => import('./pages/NotFoundPage.js')),
  },
  {
    path: "/puntos-pago",
    layout: HomeLayout,
    component: lazy(() => import('./pages/puntosPago/PuntosPagoPage.tsx')),
  },
];

export default function Rutas() {
  return rutas.map((r, index) => (
    <LayoutRouteLazy
      key={index}
      exact={true}
      path={r.path}
      layout={r.layout}
      component={r.component}
    />
  ));
}
