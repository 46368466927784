import React from "react";
import Paper from "@material-ui/core/Paper";

const EmptyLayout = ({ children, ...restProps }) => (
  <main className="cr-app bg-light" {...restProps}>
    <Paper fluid>{children}</Paper>
  </main>
);

export default EmptyLayout;
